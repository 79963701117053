const key = '316ee94e643ed2ec0663afa636f2e23e'

const requests = {
    requestPopular: `https://api.themoviedb.org/3/movie/popular?api_key=${key}&language=en-US&page=1`,
    requestTopRated: `https://api.themoviedb.org/3/movie/top_rated?api_key=${key}&language=en-US&page=1`,
    requestTrending: `https://api.themoviedb.org/3/trending/movie/day?api_key=${key}&language=en-US`,
    requestDrama: `https://api.themoviedb.org/3/discover/movie?api_key=${key}&include_adult=false&page=1&with_genres=18`,
    requestMystery: `https://api.themoviedb.org/3/discover/movie?api_key=${key}&include_adult=false&page=1&with_genres=9648`,
    requestUpcoming: `https://api.themoviedb.org/3/movie/upcoming?api_key=${key}&language=en-US&page=1`,
};

export default requests